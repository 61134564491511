import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SyncIcon from "@mui/icons-material/Sync";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmDialog from "../../Dialog-box/ConfirmDialog";
import { Base_Url } from "../../../api/api";
import ShortenedHashWithCopy from "../../../Admin-Scenes/CopyIcon/ShortenedHashWithCopy ";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: any) => string | JSX.Element;
}

interface RowData {
  [key: string]: any;
}

interface DynamicTableViewProps {
  columns: Column[];
  data: RowData[];
  confirmOpen: boolean;
  selectedItemId: number;
  handleDelete: (id: number) => void;
  showEditIcon: boolean;
  showDeleteIcon: boolean;
  handleEdit: (id: number) => void;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  page: number;
  rowsPerPage: number;
  loading: boolean;
  setConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteFunction: (id: number) => Promise<void>;
  handleSync?: (id: number) => Promise<void>;
}

const DynamicTableView: React.FC<DynamicTableViewProps> = ({
  columns,
  data,
  confirmOpen,
  selectedItemId,
  handleDelete,
  showEditIcon,
  showDeleteIcon,
  handleEdit,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  loading,
  setConfirmOpen,
  deleteFunction,
  handleSync,
}) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: "440px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    if (column.id === "action") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {showEditIcon && (
                            <IconButton
                              aria-label="edit"
                              onClick={() => handleEdit(row.id)}
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                          )}
                          {showDeleteIcon && (
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleDelete(row.id)}
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          )}
                        </TableCell>
                      );
                    } else if (column.id === "sync" && handleSync) {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <IconButton
                            aria-label="Sync"
                            onClick={() => handleSync(row.id)}
                          >
                            <SyncIcon />
                          </IconButton>
                        </TableCell>
                      );
                    } else if (column.id === "imageName") {
                      // Add your image rendering logic here
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <img
                            //  src={`/${row.imageName}`}
                            src={`${Base_Url}/${row[column.id]}`} // Assuming the URL of the image is provided in the data
                            alt={`Image for ${row.id}`} // You can customize the alt text as needed
                            style={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                              padding: "0px",
                            }} // Adjust the styling as needed
                          />
                        </TableCell>
                      );
                    } else if (
                      column.id === "parent" ||
                      column.id === "child"
                    ) {
                      // Check if the value of the column is not empty
                      const columnValue = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {columnValue ? (
                            <ShortenedHashWithCopy value={columnValue} />
                          ) : null}
                        </TableCell>
                      );
                    } else if (column.id === "group") {
                      const groupValue = row[column.id];

                      // Check the value of the "group" column and render "A" or "B" accordingly
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {groupValue === 1 ? "A" : groupValue === 2 ? "B" : ""}
                        </TableCell>
                      );
                    } else if (column.id === "required") {
                      const groupValue = row[column.id];

                      // Check the value of the "group" column and render "A" or "B" accordingly
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {groupValue === true
                            ? "Required"
                            : groupValue === 2
                            ? false
                            : "Optional"}
                        </TableCell>
                      );
                    } else if (column.id === "id") {
                      // Get the index of the current row in the data array
                      const rowIndex = data.indexOf(row);

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {rowIndex + 1}
                        </TableCell>
                      );
                    } else {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value !== "undefined"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {confirmOpen && (
        <ConfirmDialog
          title="Delete Item?"
          open={confirmOpen}
          onCancel={() => setConfirmOpen(false)}
          onConfirm={() => {
            deleteFunction(selectedItemId); // Call the parent's delete function
            console.log("Clicked on ID:", selectedItemId);
          }}
          message="Are you sure you want to delete this item?"
        />
      )}
    </Paper>
  );
};

export default DynamicTableView;
