import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import Homepage from "../Pages/Home/Home";
import CharacterDesign from "../Pages/CharacterDesign/CharacterDesign";
import WelcomePage from "../Pages/Admin/WelcomePage";
import Transactions from "../Pages/Transactions-History/Transaction";
import { redirectIfNotLoggedIn } from "./loaders";
import CampaignListing from "../Pages/Admin/CampaignListing";
import CampainDetails from "../Pages/Admin/CampaignDetails";
import CreateCampaign from "../Pages/Admin/CreateCampaign";
import Campaign from "../Components/user/Campaign/Campaign";
import Breeding from "../Pages/Breeding/Breeding";
import UsedNfts from "../Pages/Admin/UsedNfts";
import ProtectedRoutes from "./ProtectedRoutes";
import AdminManagement from "../Pages/Admin/AdminManagement";
import CreateRewardBagCampaign from "../Pages/Admin/CreateRewardBagCampaign";
import SelectCampaign from "../Pages/SelectCampaign/selectCampaign";
import RewardList from "../Pages/Reward/reward";
import SyncNfts from "../Pages/SyncNfts/SyncNfts";
import SyncCollections from "../Pages/SyncCollections/SyncCollections";
import Help from "../Pages/Help/Help";

const AllRoutes = () => {
  return <RouterProvider router={AllRoutesRouter} />;
};
export default AllRoutes;

const AllRoutesRouter = createBrowserRouter(
  createRoutesFromElements(
    // <Route path="/" element={<AppWrapper />}>
    <>
      {/* <Route
        path="characterBuilding"
        element={<CharacterDesign />}
        loader={redirectIfNotLoggedIn}
      />
      <Route
        path="breeding"
        element={<Breeding />}
        loader={redirectIfNotLoggedIn}
      /> */}

      {/* <Route path="/" element={<Navigate to="/" replace />} /> */}

      {/* route for admin panel page */}

      <Route path="/" element={<Homepage />} />
      <Route
        path="/select-campaign"
        element={<SelectCampaign />}
        loader={redirectIfNotLoggedIn}
      />
      <Route
        path="/reward"
        element={<RewardList />}
        loader={redirectIfNotLoggedIn}
      />
      <Route
        path="/admin-panel"
        element={<CampaignListing />}
        loader={redirectIfNotLoggedIn}
      />
      <Route
        path="/admin-panel/rewardbag"
        element={<CampaignListing />}
        loader={redirectIfNotLoggedIn}
      />
      {/* <Route
        path="/admin-panel/rewardbag/sync-nfts"
        element={<SyncNfts />}
        loader={redirectIfNotLoggedIn}
      /> */}
      <Route
        path="/admin-panel/rewardbag/sync-collections"
        element={<SyncCollections />}
        loader={redirectIfNotLoggedIn}
      />

      <Route
        path="/admin-panel/campaign/:id"
        element={<CampainDetails />}
        loader={redirectIfNotLoggedIn}
      />
      <Route
        path="/admin-panel/campaign/rewardbag/:id"
        element={<CampainDetails />}
        loader={redirectIfNotLoggedIn}
      />

      <Route
        path="/admin-panel/create-campaign"
        element={<CreateCampaign />}
        loader={redirectIfNotLoggedIn}
      />
      <Route
        path="/admin-panel/create-rewardbag-campaign"
        element={<CreateRewardBagCampaign />}
        loader={redirectIfNotLoggedIn}
      />

      <Route
        path="/admin-panel/usedNfts"
        element={<UsedNfts />}
        loader={redirectIfNotLoggedIn}
      />

      <Route
        path="/admin-panel/admins-management"
        element={<AdminManagement />}
        loader={redirectIfNotLoggedIn}
      />
      <Route
        path="/campaigns"
        element={<Campaign />}
        loader={redirectIfNotLoggedIn}
      />
      <Route
        path="/campaigns/rewardbag"
        element={<Campaign />}
        loader={redirectIfNotLoggedIn}
      />

      <Route
        path="/breeding/:id"
        element={<Breeding />}
        loader={redirectIfNotLoggedIn}
      />
      <Route
        path="/breeding/rewardbag/:id"
        element={<Breeding />}
        loader={redirectIfNotLoggedIn}
      />

      <Route
        path="/characterBuilding/:id"
        element={<CharacterDesign />}
        loader={redirectIfNotLoggedIn}
      />

      <Route path="/preview/:id" element={<CharacterDesign />} />

      <Route
        path="/transaction-history"
        element={<Transactions />}
        loader={redirectIfNotLoggedIn}
      />

      <Route path="/help" element={<Help />} loader={redirectIfNotLoggedIn} />
      <Route path="*" element={<WelcomePage />} />

      {/* </Route> */}
    </>
  )
);
