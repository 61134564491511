import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/system";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Stack,
  CircularProgress,
  Paper,
  Modal,
} from "@mui/material";
import { Base_Url, axiosRequestLocal } from "../../../api/api";
import { CampaignType } from "../../../../types/campaignTypes";
import OutlinedInput from "@mui/material/OutlinedInput";
import DynamicTableView from "../../Traits Components/DynamicTableView/DynamicTableView";
import { Collection } from "../../../../types/collectiontype";
import { ToastContainer, toast } from "react-toastify";
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from "formik";
import * as Yup from "yup";
import styled, { css } from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  backgroundColor: "white",
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Column {
  id: "id" | "collectionName" | "taxonId" | "group" | "createdAt" | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: any) => string | JSX.Element;
}
const columns: Column[] = [
  { id: "id", label: "ID", minWidth: 100 },
  { id: "collectionName", label: "Collection Name", minWidth: 100 }, // Customize the columns here
  { id: "taxonId", label: "Taxon Id", minWidth: 100 }, // Customize the columns here
  { id: "group", label: "Groups", minWidth: 100 }, // Customize the columns here
  { id: "createdAt", label: "Created At", minWidth: 170 },
  { id: "action", label: "Action", minWidth: 100 },
];

interface campaigns {
  campaign: CampaignType;
  setRefresh: Function;
  refresh: boolean;
}

const Collections = ({ campaign, setRefresh, refresh }: campaigns) => {
  const campaignid = campaign.campaign_id.toString();
  const campaginType = campaign.campaignType;

  //---------------------------UseStates------------------------------
  const [allCampaigns, setCampaigns] = useState<CampaignType[]>([]);
  const [specificCampaigns, setSpecificCampaign] = useState<CampaignType>();
  // get data from trait, trait ID
  const [collections, setCollection] = useState<Collection[]>([]);
  // loading
  const [loading, setLoading] = useState(false);

  //  Table View States
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // Confirm dialogue states
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<number>(0); // Add this state to track the selected item's ID for deletion
  // modal useState
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editItemId, setEditItemId] = useState<number | null>(null);
  const [editedCollectionName, setEditedCollectionName] = useState("");

  const [selectedRowData, setSelectedRowData] = useState<CollectionData | null>(
    null
  );

  const groupsData = [
    { id: 1, name: "GroupA" },
    { id: 2, name: "GroupB" },
    // Add more dummy data options as needed
  ];

  interface CollectionData {
    id: any;
    collectionName: string;
    taxonId: string;
    group: number;
  }

  interface Collection {
    id?: number;
    collectionName: string;
    taxonId: string;
    group: number;
  }

  interface FormDataInterface {
    campaignid?: string;
    collectionAddresses?: any;
  }

  const initialValues: FormDataInterface = {
    campaignid: "", // Add the correct field name for campaign ID
    collectionAddresses: [
      {
        collectionName: "",
        taxonId: "",
        group: 1,
      },
    ],
  };

  const editInitialValues: CollectionData = {
    id: selectedRowData?.id || undefined,
    collectionName: selectedRowData?.collectionName || "",
    taxonId: selectedRowData?.taxonId || "",
    group: selectedRowData?.group || 1,
  };

  // const editInitialValues: FormDataInterface = {
  //   // Only update the fields you need
  //   campaignid: "",
  //   collectionAddresses: [
  //     {
  //       collectionName: selectedRowData?.collectionName || "",
  //       taxonId: selectedRowData?.taxonId || "",
  //       group: selectedRowData?.group || 1,
  //     },
  //   ],
  // };

  const validationSchema = Yup.object().shape({
    // campaignid: Yup.string().required("Campaign name is required"),
    collectionAddresses: Yup.array().of(
      Yup.object().shape({
        collectionName: Yup.string().required("Collection Name is required"),
        taxonId: Yup.number()
          .positive("Taxon should be a positive number")
          .required("Taxon Id is required"),
        group: Yup.number().required("Group is required"),
      })
    ),
  });

  //-------------------------- Functions--------------------------
  const getAllCampaigns = async () => {
    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/campaign`,
        {},
        {}
      );
      if (response.data && response.data.campaigns) {
        setCampaigns(response.data.campaigns);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  // get collection according to Campaign Id
  const getCollectionfromCampaignId = async (campaignid: string) => {
    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/campaign/${campaignid}`,
        {},
        {}
      );

      if (response.data) {
        setSpecificCampaign(response.data.campaign);
        const collectionData = await response.data.campaign.collections;

        setCollection(collectionData);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  // delete collection
  const deleteCollection = async (id: number) => {
    try {
      const response: any = await axiosRequestLocal(
        "post",
        Base_Url + `/api/admin/collection/delete/${id}`,
        {},
        {}
      );
      getCollectionfromCampaignId(campaignid);
      toast.success("Collection Successfully Deleted", { autoClose: 3000 });
    } catch (error: any) {
      console.log("Error fetching data:", error);
      toast.error(error.response.data.message);
    }
  };

  // POST API for Form on add button clicked
  const handleFormSubmit = async (
    values: FormDataInterface,
    actions: FormikHelpers<FormDataInterface>
  ) => {
    const payload = {
      // title: values.title,
      // order: +values.order,
      collectionAddresses: values.collectionAddresses,
      campaignid: campaignid,
      // typography: values.typography,
      // theme: values.theme,
    };

    // Handle the case where background and logo are File objects or null
    setLoading(true);
    try {
      const response: any = await axiosRequestLocal(
        "post",
        Base_Url + `/api/admin/collection`,
        payload,
        undefined
      );
      actions.resetForm();
      toast.success("Collection Successfully Added", { autoClose: 3000 });
      // setTimeout(()=>{
      setLoading(false);
      // },2000)
      getCollectionfromCampaignId(campaignid);
    } catch (error: any) {
      toast.error(error.response.data.message);
      // setTimeout(()=>{
      setLoading(false);
      // },2000)
      console.error("An error occurred:", error);
    }
  };

  // -----------------------Handle Functions for Table View--------------------------
  const handleDelete = async (id: number) => {
    setSelectedItemId(id);
    setConfirmOpen(true); // open the confirmation dialog
  };

  // Define the handleEditSubmit function to handle form submission in the edit modal
  const handleEditSubmit = async (values: CollectionData) => {
    try {
      // Make an API call to update the collection item using values
      // After successfully updating, close the modal and reset edit state
      await updateCollection(values); // Replace with your update API call
      setEditModalOpen(false);
      setSelectedRowData(null);
      // Optionally, refresh the collection data to reflect changes
      getCollectionfromCampaignId(campaignid);
    } catch (error) {
      // Handle any errors here
      console.error("Error updating item:", error);
    }
  };

  const handleEdit = (id: number) => {
    // Find the collection data with the given id
    const collectionToEdit = collections.find(
      (collection) => collection.id === id
    );

    if (collectionToEdit) {
      // Create a new object of type CollectionData with the necessary properties
      const collectionData: CollectionData = {
        id: collectionToEdit.id || 0, // Use 0 or another default value if 'id' is undefined
        collectionName: collectionToEdit.collectionName,
        taxonId: collectionToEdit.taxonId,
        group: collectionToEdit.group,
      };

      // Set the selected row data and open the edit modal
      setSelectedRowData(collectionData);
      setEditModalOpen(true);
    }
  };

  const updateCollection = (values: CollectionData) => {
    setLoading(true);

    try {
      const response: any = axiosRequestLocal(
        "put",
        Base_Url + `/api/admin/collection/update/${values.id}`,
        values,
        {}
      );
      toast.success("Collection Successfully Updated", { autoClose: 3000 });
      // setTimeout(()=>{
      setLoading(false);
      // },2000)
      getCollectionfromCampaignId(campaignid);
    } catch (error: any) {
      toast.error(error.response.data.message);
      // setTimeout(()=>{
      setLoading(false);
      // },2000)
      console.log("Error fetching data:", error);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  // const handleChangeOfCampaignId = (event: SelectChangeEvent<string>) => {
  //   setCampaignId(event.target.value);
  // };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // ------------------------Styled Components------------------------
  const RedButton = styled(Button)`
    && {
      background-color: red;
      color: white;
      &:hover {
        background-color: darkred;
      }
    }
  `;

  const GreenButton = styled(Button)`
    && {
      background-color: green;
      color: white;
      &:hover {
        background-color: darkgreen;
      }
    }
  `;
  //-------------------------- UseEffects -----------------------------
  useEffect(() => {
    getAllCampaigns();
  }, []);

  useEffect(() => {
    if (campaignid !== "") {
      getCollectionfromCampaignId(campaignid);
    }
  }, [campaignid, refresh]);

  return (
    <Container sx={{ borderRadius: 4, mt: 2 }}>
      <Paper elevation={4} sx={{ borderRadius: 4 }}>
        <Box
          padding={3}
          // borderRadius={5}
          sx={{
            borderRadius: 4,
            backgroundColor: "white",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Collection
          </Typography>

          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ mt: "2px" }}></Grid>
                <Typography mt={"20px"}>List of Collections</Typography>

                <Box sx={{ marginTop: "10px" }}>
                  {loading ? (
                    <Box display="flex" justifyContent="center" mt={3}>
                      <CircularProgress size={70} sx={{ color: "black" }} />
                    </Box>
                  ) : (
                    <DynamicTableView
                      columns={columns}
                      data={collections}
                      loading={loading}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      handleDelete={handleDelete}
                      showEditIcon={true}
                      showDeleteIcon={true}
                      handleEdit={handleEdit}
                      confirmOpen={confirmOpen}
                      setConfirmOpen={setConfirmOpen}
                      selectedItemId={selectedItemId}
                      deleteFunction={deleteCollection}
                    />
                  )}
                </Box>

                <Grid container spacing={2} sx={{ mt: "2px" }}>
                  {specificCampaigns?.campaignType &&
                  specificCampaigns?.campaignType == "1" ? (
                    <>
                      <Grid lg={12} sm={12} xs={12} item>
                        <Box sx={{ width: "91%" }}>
                          <FieldArray name="collectionAddresses">
                            {({ push, remove }) => (
                              <div>
                                {values.collectionAddresses.map(
                                  (_: any, index: any) => (
                                    <Box
                                      key={index}
                                      style={{
                                        display: "flex",
                                        // alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            color: "#555555",
                                            fontSize: 12,
                                          }}
                                        >
                                          Collection Name
                                        </Typography>
                                        <div>
                                          <Field
                                            name={`collectionAddresses[${index}].collectionName`}
                                            render={({
                                              field,
                                              meta,
                                            }: FieldProps<string>) => (
                                              <TextField
                                                id={`collectionName-${index}`}
                                                type="text"
                                                placeholder="Collection Name"
                                                size="small"
                                                sx={{
                                                  marginTop: "2px",
                                                  width: "250px",
                                                }}
                                                {...field}
                                                error={
                                                  meta?.touched && !!meta?.error
                                                }
                                                helperText={
                                                  meta?.touched && meta?.error
                                                }
                                              />
                                            )}
                                          />
                                        </div>
                                      </div>

                                      <div>
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            color: "#555555",
                                            fontSize: 12,
                                          }}
                                        >
                                          Taxon Id
                                        </Typography>
                                        <div>
                                          <Field
                                            name={`collectionAddresses[${index}].taxonId`}
                                            render={({
                                              field,
                                              meta,
                                            }: FieldProps<string>) => (
                                              <TextField
                                                id={`taxonId-${index}`}
                                                type="text"
                                                placeholder="Taxon Id"
                                                size="small"
                                                sx={{
                                                  marginTop: "2px",
                                                  width: "250px",
                                                }}
                                                {...field}
                                                error={
                                                  meta?.touched && !!meta?.error
                                                }
                                                helperText={
                                                  meta?.touched && meta?.error
                                                }
                                              />
                                            )}
                                          />
                                        </div>
                                      </div>

                                      <div style={{ paddingTop: "3px" }}>
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            color: "#555555",
                                            fontSize: 12,
                                          }}
                                        >
                                          Groups
                                        </Typography>
                                        <FormControl
                                          style={{ minWidth: 120 }}
                                          size="small"
                                          // error={
                                          //   touched?.collectionAddresses &&
                                          //   touched?.collectionAddresses[index] &&
                                          //   Boolean(
                                          //     (errors?.collectionAddresses as FormikErrors<Collection>[])[index]?.group
                                          //   )
                                          // }
                                        >
                                          <Select
                                            name={`collectionAddresses[${index}].group`}
                                            displayEmpty
                                            onChange={handleChange}
                                            input={<OutlinedInput />}
                                            MenuProps={MenuProps}
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                            sx={{ width: "250px" }}
                                            renderValue={(selected) => {
                                              if (!selected) {
                                                return (
                                                  <Typography>
                                                    Groups
                                                  </Typography>
                                                );
                                              }
                                              const selectedGroups =
                                                groupsData.find(
                                                  (groupsData) =>
                                                    groupsData?.id ===
                                                    Number(selected)
                                                );
                                              return selectedGroups ? (
                                                selectedGroups?.name
                                              ) : (
                                                <Typography>Groups</Typography>
                                              );
                                            }}
                                          >
                                            <MenuItem value={1}>
                                              Group A
                                            </MenuItem>
                                            <MenuItem value={2}>
                                              Group B
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                        {/* <div>temp error</div> */}
                                        {/* <FormHelperText className="error-message">
                                {(errors?.collectionAddresses as FormikErrors<Collection>[])[index]?.group}
                              </FormHelperText> */}
                                      </div>
                                      <div style={{ paddingTop: "18px" }}>
                                        <RedButton
                                          type="button"
                                          onClick={() => remove(index)}
                                        >
                                          <DeleteIcon />
                                        </RedButton>
                                      </div>
                                    </Box>
                                  )
                                )}
                                <GreenButton
                                  type="button"
                                  sx={{ marginLeft: "2px", marginTop: "9px" }}
                                  onClick={() =>
                                    push({
                                      collectionName: "",
                                      taxonId: "",
                                    })
                                  }
                                >
                                  <AddIcon />
                                </GreenButton>
                              </div>
                            )}
                          </FieldArray>
                        </Box>
                      </Grid>
                    </>
                  ) : specificCampaigns?.campaignType == "2" ? (
                    <>
                      <Grid lg={12} sm={12} xs={12} item>
                        <Box sx={{ width: "91%" }}>
                          <FieldArray name="collectionAddresses">
                            {({ push, remove }) => (
                              <div>
                                {values.collectionAddresses.map(
                                  (_: any, index: any) => (
                                    <Box
                                      key={index}
                                      style={{
                                        display: "flex",
                                        // alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            color: "#555555",
                                            fontSize: 12,
                                          }}
                                        >
                                          Collection Name
                                        </Typography>
                                        <div>
                                          <Field
                                            name={`collectionAddresses[${index}].collectionName`}
                                            render={({
                                              field,
                                              meta,
                                            }: FieldProps<string>) => (
                                              <TextField
                                                id={`collectionName-${index}`}
                                                type="text"
                                                placeholder="Collection Name"
                                                size="small"
                                                sx={{
                                                  marginTop: "2px",
                                                  width: "250px",
                                                }}
                                                {...field}
                                                error={
                                                  meta?.touched && !!meta?.error
                                                }
                                                helperText={
                                                  meta?.touched && meta?.error
                                                }
                                              />
                                            )}
                                          />
                                        </div>
                                      </div>

                                      <div>
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            color: "#555555",
                                            fontSize: 12,
                                          }}
                                        >
                                          Taxon Id
                                        </Typography>
                                        <div>
                                          <Field
                                            name={`collectionAddresses[${index}].taxonId`}
                                            render={({
                                              field,
                                              meta,
                                            }: FieldProps<string>) => (
                                              <TextField
                                                id={`taxonId-${index}`}
                                                type="text"
                                                placeholder="Taxon Id"
                                                size="small"
                                                sx={{
                                                  marginTop: "2px",
                                                  width: "250px",
                                                }}
                                                {...field}
                                                error={
                                                  meta?.touched && !!meta?.error
                                                }
                                                helperText={
                                                  meta?.touched && meta?.error
                                                }
                                              />
                                            )}
                                          />
                                        </div>
                                      </div>

                                      <div style={{ paddingTop: "3px" }}>
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            color: "#555555",
                                            fontSize: 12,
                                          }}
                                        >
                                          Groups
                                        </Typography>
                                        <FormControl
                                          style={{ minWidth: 120 }}
                                          size="small"
                                          // error={
                                          //   touched?.collectionAddresses &&
                                          //   touched?.collectionAddresses[index] &&
                                          //   Boolean(
                                          //     (errors?.collectionAddresses as FormikErrors<Collection>[])[index]?.group
                                          //   )
                                          // }
                                        >
                                          <Select
                                            name={`collectionAddresses[${index}].group`}
                                            displayEmpty
                                            onChange={handleChange}
                                            input={<OutlinedInput />}
                                            MenuProps={MenuProps}
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                            sx={{ width: "250px" }}
                                            renderValue={(selected) => {
                                              if (!selected) {
                                                return (
                                                  <Typography>
                                                    Groups
                                                  </Typography>
                                                );
                                              }
                                              const selectedGroups =
                                                groupsData.find(
                                                  (groupsData) =>
                                                    groupsData?.id ===
                                                    Number(selected)
                                                );
                                              return selectedGroups ? (
                                                selectedGroups?.name
                                              ) : (
                                                <Typography>Groups</Typography>
                                              );
                                            }}
                                          >
                                            <MenuItem value={1}>
                                              Group A
                                            </MenuItem>
                                            <MenuItem value={2} disabled>
                                              Group B
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                        {/* <FormHelperText
                                      className="error-message">
                                {(errors?.collectionAddresses as FormikErrors<Collection>[])[index]?.group}
                              </FormHelperText> */}
                                      </div>
                                      <div style={{ paddingTop: "18px" }}>
                                        <RedButton
                                          type="button"
                                          onClick={() => remove(index)}
                                        >
                                          <DeleteIcon />
                                        </RedButton>
                                      </div>
                                    </Box>
                                  )
                                )}
                                <GreenButton
                                  type="button"
                                  sx={{ marginLeft: "2px", marginTop: "9px" }}
                                  onClick={() =>
                                    push({
                                      collectionName: "",
                                      taxonId: "",
                                    })
                                  }
                                >
                                  <AddIcon />
                                </GreenButton>
                              </div>
                            )}
                          </FieldArray>
                        </Box>
                      </Grid>
                    </>
                  ) : specificCampaigns?.campaignType == "3" ? (
                    <div></div>
                  ) : (
                    <>
                      <Grid lg={12} sm={12} xs={12} item>
                        <Box sx={{ width: "91%" }}>
                          <FieldArray name="collectionAddresses">
                            {({ push, remove }) => (
                              <div>
                                {values.collectionAddresses.map(
                                  (_: any, index: any) => (
                                    <Box
                                      key={index}
                                      style={{
                                        display: "flex",
                                        // alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            color: "#555555",
                                            fontSize: 12,
                                          }}
                                        >
                                          Collection Name
                                        </Typography>
                                        <div>
                                          <Field
                                            name={`collectionAddresses[${index}].collectionName`}
                                            render={({
                                              field,
                                              meta,
                                            }: FieldProps<string>) => (
                                              <TextField
                                                id={`collectionName-${index}`}
                                                type="text"
                                                placeholder="Collection Name"
                                                size="small"
                                                sx={{
                                                  marginTop: "2px",
                                                  width: "250px",
                                                }}
                                                {...field}
                                                error={
                                                  meta.touched && !!meta.error
                                                }
                                                helperText={
                                                  meta.touched && meta.error
                                                }
                                              />
                                            )}
                                          />
                                        </div>
                                      </div>

                                      <div>
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            color: "#555555",
                                            fontSize: 12,
                                          }}
                                        >
                                          Taxon Id
                                        </Typography>
                                        <div>
                                          <Field
                                            name={`collectionAddresses[${index}].taxonId`}
                                            render={({
                                              field,
                                              meta,
                                            }: FieldProps<string>) => (
                                              <TextField
                                                id={`taxonId-${index}`}
                                                type="text"
                                                placeholder="Taxon Id"
                                                size="small"
                                                sx={{
                                                  marginTop: "2px",
                                                  width: "250px",
                                                }}
                                                {...field}
                                                error={
                                                  meta?.touched && !!meta?.error
                                                }
                                                helperText={
                                                  meta?.touched && meta?.error
                                                }
                                              />
                                            )}
                                          />
                                        </div>
                                      </div>

                                      <div style={{ paddingTop: "3px" }}>
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            color: "#555555",
                                            fontSize: 12,
                                          }}
                                        >
                                          Groups
                                        </Typography>
                                        <FormControl
                                          style={{ minWidth: 120 }}
                                          size="small"
                                        >
                                          <Select
                                            name={`collectionAddresses[${index}].group`}
                                            displayEmpty
                                            onChange={handleChange}
                                            input={<OutlinedInput />}
                                            MenuProps={MenuProps}
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                            sx={{ width: "250px" }}
                                            renderValue={(selected) => {
                                              if (!selected) {
                                                return (
                                                  <Typography>
                                                    Groups
                                                  </Typography>
                                                );
                                              }
                                              const selectedGroups =
                                                groupsData.find(
                                                  (groupsData) =>
                                                    groupsData?.id ===
                                                    Number(selected)
                                                );
                                              return selectedGroups ? (
                                                selectedGroups?.name
                                              ) : (
                                                <Typography>Groups</Typography>
                                              );
                                            }}
                                          >
                                            <MenuItem value={1}>
                                              Group A
                                            </MenuItem>
                                            <MenuItem value={2}>
                                              Group B
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                        {/* <ErrorMessage
                                        name={`collectionAddresses[${index}].group`}
                                        component="div"
                                        className="error-message"
                                      /> */}
                                      </div>
                                      <div style={{ paddingTop: "18px" }}>
                                        <RedButton
                                          type="button"
                                          onClick={() => remove(index)}
                                        >
                                          <DeleteIcon />
                                        </RedButton>
                                      </div>
                                    </Box>
                                  )
                                )}
                                <GreenButton
                                  type="button"
                                  sx={{ marginLeft: "2px", marginTop: "9px" }}
                                  onClick={() =>
                                    push({
                                      collectionName: "",
                                      taxonId: "",
                                    })
                                  }
                                >
                                  <AddIcon />
                                </GreenButton>
                              </div>
                            )}
                          </FieldArray>
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>

                <Box display="flex" justifyContent="start" mt="40px">
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#454545",
                      width: "100px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {loading === false ? (
                      "Update"
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        {isEditModalOpen && (
          <Modal
            open={isEditModalOpen}
            onClose={() => {
              setEditModalOpen(false);
              setSelectedRowData(null); // Clear selected row data when closing the modal
            }}
          >
            <Paper elevation={4} sx={{ ...style, width: 350, borderRadius: 4 }}>
              <Typography variant="h5">Edit Collection</Typography>

              <Formik
                initialValues={editInitialValues}
                onSubmit={handleEditSubmit}
              >
                <Form>
                  {/* Your form fields go here */}
                  <Field
                    name="collectionName"
                    render={({ field, meta }: FieldProps<string>) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        error={meta.touched && !!meta.error}
                        helperText={meta.touched && meta.error}
                      />
                    )}
                  />
                  <Field
                    name="taxonId"
                    render={({ field, meta }: FieldProps<string>) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        sx={{ marginTop: "10px" }}
                        error={meta.touched && !!meta.error}
                        helperText={meta.touched && meta.error}
                      />
                    )}
                  />
                  <Field
                    name="group"
                    render={({ field, meta }: FieldProps<number>) => (
                      <Select
                        {...field}
                        fullWidth
                        variant="outlined"
                        sx={{ marginTop: "10px" }}
                        error={meta.touched && !!meta.error}
                      >
                        <MenuItem value={1}>Group A</MenuItem>
                        <MenuItem value={2}>Group B</MenuItem>
                      </Select>
                    )}
                  />
                  <Box sx={{ marginTop: "10px" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ marginTop: "10px" }}
                    >
                      Update
                    </Button>
                    <Button
                      onClick={() => {
                        setEditModalOpen(false);
                      }}
                      variant="contained"
                      color="error"
                      sx={{ marginTop: "10px", marginLeft: "5px" }}
                    >
                      Close
                    </Button>
                  </Box>
                </Form>
              </Formik>
            </Paper>
          </Modal>
        )}

        <ToastContainer />
      </Paper>
    </Container>
  );
};

export default Collections;
