import React, { useEffect, useState } from "react";
import { axiosRequestAuth, Base_Url } from "../../Components/api/api";
//import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  CircularProgress,
  TablePagination,
  Button,
  Box,
  Container,
  Grid,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Logo from "../../assets/BreedingAssets/logo.png";
import BreedingBackground from "../../assets/BreedingAssets/BreedingBackground.png";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  boxShadow: "0px 0px 12px rgba(0,0,0,0.1)",
  maxHeight: "80vh",
  overflowY: "auto",
}));

const StyledHeaderCell = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: "bold",
  fontSize: "0.875rem",
}));

const StyledChipClaimed = styled(Chip)(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: theme.palette.success.light,
  color: theme.palette.success.contrastText,
}));

const StyledChipUnclaimed = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
  color: theme.palette.error.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
}));
interface DataItem {
  id: number;
  nftoken_id: string;
  user_wallet_address: string;
  image_link: string;
  offer_id: string;
  is_claimed: boolean;
  createdAt: string;
  updatedAt: string;
}
const MyTable: React.FC<{
  data: DataItem[];
  transferNft: (nftId: string) => void;
  mobileViewCheck: any;
}> = ({ data, transferNft, mobileViewCheck }) => {
  return (
    <TableContainer sx={{ maxHeight: "63vh", overflowY: "auto" }}>
      <Table
        style={{
          backgroundColor: "rgb(255,255,255,0.7)",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                m: 0,
                p: 0,
                px: mobileViewCheck ? 1 : 0.7,
                py: 1,
              }}
            >
              ID
            </TableCell>

            {/* <TableCell>Won NFT</TableCell> */}
            {/* <TableCell
              sx={{
                m: 0,
                p: 0,
                px: mobileViewCheck ? 1 : 0.7,
                py: 1,
              }}
            >
              Name
            </TableCell> */}

            <TableCell
              sx={{
                m: 0,
                p: 0,
                px: mobileViewCheck ? 1 : 0.7,
                py: 1,
              }}
            >
              NFT Token ID
            </TableCell>

            <TableCell
              sx={{
                m: 0,
                p: 0,
                px: mobileViewCheck ? 1 : 0.7,
                py: 1,
              }}
            >
              Image
            </TableCell>
            <TableCell
              sx={{
                m: 0,
                p: 0,
                px: mobileViewCheck ? 1 : 0.7,
                py: 1,
              }}
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell
                sx={{
                  m: 0,
                  p: 0,
                  px: mobileViewCheck ? 1 : 0.7,
                  py: 1,
                }}
              >
                {row.id}
              </TableCell>

              {/* <TableCell>{row.wonNft}</TableCell> */}
              {/* <TableCell
                sx={{
                  m: 0,
                  p: 0,
                  px: mobileViewCheck ? 1 : 0.7,
                  py: 1,
                }}
              >
                {row.wonNft?.content?.name}
              </TableCell> */}
              {mobileViewCheck && (
                <TableCell
                  sx={{
                    m: 0,
                    p: 0,
                    px: mobileViewCheck ? 1 : 0.7,
                    py: 1,
                  }}
                >
                  {row.nftoken_id}
                </TableCell>
              )}
              {!mobileViewCheck && (
                <TableCell
                  sx={{
                    m: 0,
                    p: 0,
                    px: mobileViewCheck ? 1 : 0.7,
                    py: 1,
                  }}
                >
                  <Tooltip title={row.nftoken_id}>
                    <span>{row.nftoken_id?.substring(0, 8) + ".."}</span>
                  </Tooltip>
                </TableCell>
              )}

              <TableCell
                sx={{
                  m: 0,
                  p: 0,
                  px: mobileViewCheck ? 1 : 0.7,
                  py: 1,
                }}
              >
                <img
                  src={row.image_link}
                  alt="icon"
                  style={{ width: "40px", height: "40px" }}
                />
              </TableCell>
              <TableCell
                sx={{
                  m: 0,
                  p: 0,
                  px: mobileViewCheck ? 1 : 0.7,
                  py: 1,
                }}
              >
                {row.is_claimed ? (
                  <StyledChipClaimed
                    icon={<CheckCircleIcon style={{ color: "white" }} />}
                    label="Claimed"
                  />
                ) : (
                  <StyledChipUnclaimed
                    label="Claim"
                    onClick={() => transferNft(row.offer_id)}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function RewardList() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentRouteName = location.pathname;
  const [rewardHistory, setRewardHistory] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const mobileViewCheck = useMediaQuery("(min-width:1023px)");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const transferNft = async (nftId: string) => {
    toast("Check your xumm wallet.", { autoClose: 3000 });
    setLoading(true);
    try {
      const response: any = await axiosRequestAuth(
        "post",
        `api/luxlion/v2/rewardbag/transfernft`,
        { offer_id: nftId },
        {}
      );

      //console.log(response, "Get api");
      if (response?.data?.success) {
        toast.success("NFT reward claimed successfully.", { autoClose: 3000 });
        // setRewardHistory(
        //   rewardHistory.map((item) =>
        //     item.id === response.data.updatedHistory.id
        //       ? response.data.updatedHistory
        //       : item
        //   )
        // );
        getData(page + 1, rowsPerPage);
        //setLoading(false);
      } else if (!response?.data?.success) {
        setLoading(false);
        toast.error(response?.data?.message || "Request Failed.", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error("Request Failed.", {
        autoClose: 3000,
      });
    }
  };

  const getData = async (page: number, limit: number) => {
    setLoading(true);
    try {
      const response: any = await axiosRequestAuth(
        "get",
        `api/luxlion/v2/rewardbag/history?page=${page}&limit=${limit}`,
        {},
        {}
      );

      //console.log(response, "Get api");
      if (response.status) {
        setRewardHistory(response.data.rewardBagHistory.data);
        setTotalItems(response.data.rewardBagHistory.total_items);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(page + 1, rowsPerPage);
  }, [page, rowsPerPage]);

  return (
    <Box
      sx={{
        minHeight: "100vh",

        backgroundImage: `url(${BreedingBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "transparent",
          //zIndex: 10,
          borderBottom: "1px",
        }}
      >
        <ArrowBackIcon
          onClick={() => {
            navigate("/select-campaign");
          }}
          sx={{ cursor: "pointer", color: "white", p: 0, m: 0, ml: 3 }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mr: 10,
            pt: 2,
          }}
        >
          <img
            src={Logo}
            alt="icon"
            style={{ width: "140px", height: "40px" }}
          />
        </Box>
        <Box></Box>
      </Box>
      <Container
        sx={{
          borderRadius: 4,
          mt: 2,
          //backgroundColor: "rgb(0,0,0,0.5)",

          //opacity: 0.9,
        }}
      >
        <Box
          sx={{
            borderRadius: 4,
            backgroundColor: "none !important",
            color: "white",
            pb: 2,
          }}
        >
          <Box
            // borderRadius={5}
            sx={{
              px: mobileViewCheck ? 3 : 0,
              py: 1,
              borderRadius: 4,
            }}
          >
            <Typography
              variant="h4"
              fontWeight={"bold"}
              sx={{ backgroundColor: "rgb(0,0,0,0.2)", width: "fit-content" }}
            >
              Rewards List
            </Typography>

            <Grid container spacing={2} sx={{ mt: "2px" }}>
              {/*---------------- Select Trait--------------- */}
            </Grid>

            <Box>
              <Box
                sx={{
                  marginTop: "10px",
                  backgroundColor: "rgb(255,255,255,0.7)",
                }}
              >
                {loading ? (
                  <Box display="flex" justifyContent="center" mt={3}>
                    <CircularProgress size={70} sx={{ color: "black" }} />
                  </Box>
                ) : (
                  <>
                    {rewardHistory && (
                      <MyTable
                        data={rewardHistory}
                        transferNft={transferNft}
                        mobileViewCheck={mobileViewCheck}
                      />
                    )}
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15]}
                      component="div"
                      count={totalItems}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <ToastContainer />
        </Box>
      </Container>
    </Box>
  );
}

export default RewardList;
